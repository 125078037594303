import { LocationProvider } from "@reach/router";
import classnames from "classnames";
import _, { add } from "lodash";
import "moment/locale/de-ch";
import "moment/locale/he";
import React from "react";
import ReactPixel from "react-facebook-pixel";
import FullStory from "react-fullstory";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import "regenerator-runtime/runtime";
import AppContainer from "../components/AppContainer";
import AuthView from "../components/AuthView";
import FontLoader from "../components/FontLoader";
import GiftsFlowHeader from "../components/GiftsFlowHeader";
import GoogleMapsLoader from "../components/GoogleMapsLoader";
import Header from "../components/Header";
import HappysPizzaCustomRightSide from "../components/Header/HappysPizzaCustomRightSide";
import HeaderCheckoutBagToggle from "../components/Header/HeaderCheckoutBagToggle";
import HeaderRightSide from "../components/Header/HeaderRightSide";
import HeaderUserProfileLink from "../components/Header/HeaderUserProfileLink";
import { LOGIN_TYPES } from "../components/LoginView";
import TemporarySplashScreen from "../components/TemporarySplashScreen";
import WaitingOrders from "../components/waiting-orders";
import withTranslation from "../hocs/withTranslation";
import { BUILD_TARGETS, TargetContext } from "../target-context";
import {
  closeAuthModal,
  closeAuthView,
  closeCheckoutView,
  openAuthView,
  openCheckoutView,
  setCameraPermissionsAndroid,
  setEditorAppStyles,
  setEditorPromotions,
  setGoogleLoaded,
  setGroupAppModeParams,
  setKeyboardOpen,
  setKioskLocationId,
  toggleCheckoutView,
  openNewOrPrevOrderModal,
  closeNewOrPrevOrderModal
} from "../store/app/actions";
import { resetGiftRedeem } from "../store/giftRedeem/actions";
import { applyParamsToOrder, startNewOrder, syncOrderItems } from "../store/order/actions";
import {
  archiveWaitingOrder,
  closePrivacyConsent,
  getUnseenGiftsAmount,
  initUniqueIdentifierIfNeeded,
  loadContactsFailed,
  loadContactsSuccess,
  loadLoyaltyProfile,
  loadUserDetails,
  loadWaitingOrders,
  markShowedPushApprovalPopup,
  notifyIamHere,
  pollPendingPurchaseEvents,
  pollStatusPurchaseEvent,
  registerPushNotifications,
  resetAuth,
  showPrivacyConsent,
  addDeepLinkParamsToUser
} from "../store/user/actions";
import * as USER_ACTION_TYPE from "../store/user/constants";
import { applyBusinessStyles } from "../styles";
import { fireConversionEvent } from "../utils/gtm";
import {
    getParams, getUserCreds, hasUserCreds, isGroupReferral, navigateTo, removeParams, setParams
} from "../utils/location";
import moment from "../utils/moment-timezone-with-data";
import setIconBadgeNumberInNative from "../utils/setIconBadgeNumberInNative";
import Helmet from "./Helmet";
import "./index.global.scss";
import getTitleAndBackPath from "./pathConfig";

import {changeLocation} from "../store/header/actions";
import {getPromotions, getStyles} from "../store/selectors";

import AppTabs from "../components/app-tabs";
import * as APP_ACTION_TYPE from "../store/app/constants";
import * as styles from "./CheckoutSideBar.module.scss";
import {HOMEPAGE_TEMPLATE_KEY} from "../utils/constants";
import {isNewsfeedTemplateLayout} from "../logic/templates/isNewsfeedTemplateLayout";
import {isTemplateEnabled} from "../logic/templates/isTemplateEnabled";

class TemplateWrapper extends React.Component {
  state = {showTemporarySplashScreen: false, layoutReady: false};
  idleTimerRef = null;
  googleLoaded = false;

  constructor(props) {
    super(props);
    LocationProvider.defaultProps.history.listen((history) => {
      this.props.changeLocation(LocationProvider.defaultProps.history.location);
    });

    this.initializeMomentLocale();
  }

  componentDidMount() {
    this.subscribeCrossWindowMessaging();
    if (!this.props.pageContext.business) {
      navigateTo("/");
      return;
    }
    const {
      appStyles,
      pageContext: {
        business: { scheme, mapsCredentialsKey },
        businessAppConfiguration,
      },
      user,
      openAuthView,
      location,
      app: { isMobile }
    } = this.props;
    // for handling location changes
    const isNative = typeof window != "undefined" && window.isNativeApp;
    this.layoutReadyTimout = setTimeout(() => {
      this.setState({ layoutReady: true });
    }, 500);

    const {registerPushNotifications: {
      isNewsApproved,
      isPurchaseApproved,
      pushIdentifier, os
    }} = user;
    const temporarySplashScreenEnabled = _.get(
      scheme,
      "showTemporarySplashScreen",
    );
    const checkForPendingPurchaseEvents = _.get(
      businessAppConfiguration,
      "checkForPendingPurchaseEvents",
    );

    mapsCredentialsKey &&
      console.log(
        "using maps api key starts with:",
        (mapsCredentialsKey || "").slice(0, 4),
      );

    this.fireGTMStartPageEventsIfNeeded();
    this.initFacebookPixelIfNeeded();
    this.initGoogleAnalyticsIfNeeded();

    this.forceRenderOnWidthChange();
    this.startNativeAppPoller();

    this.props.initUniqueIdentifierIfNeeded();
    this.params = getParams(location);

    if (
      !user.loggedIn &&
      (this.params.openSignup || //appStyles.showSignupFirstOnNative &&
        (isNative && location.pathname === "/"))
    ) {
      if (this.params.openSignup || isMobile) {
        openAuthView();
      }
    }

    if (user.loggedIn){
      if ((isNewsApproved || isPurchaseApproved) && isNative) {
        window.postMessage(
          JSON.stringify({
            type: "PUSH_NOTIFICATIONS_TOGGLE_ON",
            payload: {isNewsApproved, isPurchaseApproved},
          }),
        );
      }

      if (getParams(location).deepLinkGroupHash){
        this.props.addDeepLinkParamsToUser(this.params);
        navigateTo(removeParams(location, ["deepLinkGroupHash"]));
      }
    }

    if (
      _.startsWith(location.pathname, "/order") &&
      isGroupReferral(this.params)
    ) {
      if (hasUserCreds(this.params)) {
        this.props.loadUserDetails(getUserCreds(this.params));
        // TODO: navigateTo(removeParams(history.location, ));
      }
    }

    if (location.pathname === "/") {
      this.props.resetGiftRedeem();

      if (temporarySplashScreenEnabled && isNative) {
        this.splashScreenDelay = setTimeout(
          this.openTemporarySplashScreen,
          1000 * 1,
        );

        this.splashScreenTimeout = setTimeout(
          this.closeTemporarySplashScreen,
          1000 * (2.5 + 8),
        );
      }
    }

    if (typeof window !== "undefined") {
      window.webappActionHandler = (action) => {
        switch (action.type) {
          case NATIVE_INIT:
            navigateTo(setParams(action.payload.pathname, this.params));
            break;
          case CHANGE_PATH:
            this.changePath(action.payload.pathname);
            break;
          case USER_ACTION_TYPE.LOAD_CONTACTS.SUCCESS:
            this.props.loadContactsSuccess(action.payload);
            break;
          case USER_ACTION_TYPE.LOAD_CONTACTS.FAILED:
            this.props.loadContactsFailed(action.payload);
            break;
          case APP_ACTION_TYPE.KEYBOARD_OPENED:
            this.props.setKeyboardOpen(true);
            break;
          case APP_ACTION_TYPE.KEYBOARD_CLOSED:
            this.props.setKeyboardOpen(false);
            break;
          case GO_TO_PREV_PAGE:
            this.goToPrevPage();
            break;
          case USER_ACTION_TYPE.REGISTER_PUSH_NOTIFICATION_FROM_NATIVE:
            this.props.registerPushNotifications(action.payload);
            break;
          case APP_ACTION_TYPE.RECEIVE_CAMERA_PERMISSIONS_ANDROID:
            this.setCameraPermissionsAndroid(action.payload);
            break;
          case USER_ACTION_TYPE.NOTIFICATION_RECEIVED:
            console.log("NOTIFICATION RECEIVED!!!!");
            console.log(action.payload);
            this.props.getUnseenGiftsAmount();
            break;
          default:
            break;
        }
      };
    }

    if (window.isNativeApp && checkForPendingPurchaseEvents) {
      if (this.props.user.shouldPollPendingPurchaseEvents) {
        this.props.pollPendingPurchaseEvents();
      } else if (
        this.props.user.shouldPollStatusPurchaseEvent &&
        !_.isEmpty(this.props.user.currentPurchaseEvent)
      ) {
        this.props.pollStatusPurchaseEvent(
          this.props.user.currentPurchaseEvent.id,
        );
      }
    }

    if (!user.loggedIn) {
      setIconBadgeNumberInNative(0);
    }
    this.props.getUnseenGiftsAmount();

    if (user.loggedIn) {
      this.props.loadLoyaltyProfile();
    }

    window.setEditorAppStyles = this.props.setEditorAppStyles;
    window.setEditorPromotions = this.props.setEditorPromotions;
  }

  subscribeCrossWindowMessaging = () => {
    window.addEventListener("message", (event) => {
      // if (event.origin != "http://javascript.info") {
      //   // something from an unknown domain, let's ignore it
      //   return;
      // }
      // alert("received: " + event.data);
      // can message back using event.source.postMessage(...)
      if (event.data && event.data.appStyles) {
        console.log("before:", this.props.appStyles);
        console.log("received: ", event.data);
        const appStyles = _.fromPairs(
          _.filter(
            _.toPairs(event.data.appStyles),
            ([key, value]) =>
              _.isBoolean(value) ||
              (value && !_.isEmpty(value)) ||
              _.isNumber(value),
          ),
        );
        console.log("after cleanup: ", { appStyles });
        this.props.setEditorAppStyles(applyBusinessStyles(appStyles));
      }

      if (event.data && event.data.promotions) {
        console.log("before:", this.props.promotions);
        console.log("received: ", event.data);

        this.props.setEditorPromotions(event.data.promotions);
      }
    });
  };

  forceRenderOnWidthChange = () => {
    window.addEventListener(
      "resize",
      _.debounce(() => this.forceUpdate(), 100),
    );
  };

  startNativeAppPoller = () => {
    const {
      pageContext: { businessAppConfiguration },
    } = this.props;
    const checkForPendingPurchaseEvents = _.get(
      businessAppConfiguration,
      "checkForPendingPurchaseEvents",
    );
    this.isNativeAppPoller = setInterval(() => {
      const prevIsNativeApp = this.isNativeApp;
      this.isNativeApp = window.isNativeApp;

      if (!prevIsNativeApp && this.isNativeApp) {
        console.log("updated native app status");

        if (checkForPendingPurchaseEvents) {
          if (this.props.user.shouldPollPendingPurchaseEvents) {
            this.props.pollPendingPurchaseEvents();
          } else if (
            this.props.user.shouldPollStatusPurchaseEvent &&
            !_.isEmpty(this.props.user.currentPurchaseEvent)
          ) {
            this.props.pollStatusPurchaseEvent(
              this.props.user.currentPurchaseEvent.id,
            );
          }
        }
        console.log("stopped polling native app status");
        this.notifyLoginOnMount();
        clearInterval(this.isNativeAppPoller);
      }
    }, 1000);
  };

  initFacebookPixelIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings },
      },
    } = this.props;

    // Currently only single facebook pixel is supported.
    const facebookTrackings = _.filter(trackings, { type: "facebook" });

    if (facebookTrackings) {
      _.map(facebookTrackings, (facebookTracking) => {
        ReactPixel.init(facebookTracking.trackingId);
      });

      if (
        _.filter(facebookTrackings, (facebookTracking) =>
          _.find(facebookTracking.events, { type: "PageView" }),
        )
      ) {
        ReactPixel.pageView();
      }
    }
  };

  initGoogleAnalyticsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings },
      },
      location,
    } = this.props;
    const gaTrackings = _.filter(trackings, { type: "ga" });
    const trackingIds = _.map(gaTrackings, ({ trackingId }, index) => ({
      trackingId,
      ...(index && { gaOptions: { name: trackingId } }),
    }));

    if (!_.isEmpty(trackingIds)) {
      ReactGA.initialize(trackingIds, {
        alwaysSendToDefaultTracker: trackingIds.length < 2,
      });
      ReactGA.pageview(location.pathname + location.search);
    }
  };

  fireGTMStartPageEventsIfNeeded = () => {
    const {
      pageContext: {
        business: { trackings },
      },
    } = this.props;

    const startPageGtmTrackingIds = _.compact(
      _.map(
        _.filter(_.flatMap(_.filter(trackings, { type: "gtm" }), "events"), {
          type: "StartPage",
        }),
        "params.id",
      ),
    );

    _.forEach(startPageGtmTrackingIds, fireConversionEvent);
  };

  initializeMomentLocale = () => {
    const locale = this.props.appStyles.locale || "en-US";
    console.log(`Running app with locale: ${locale}`);
    moment.locale(locale);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.user.loggedIn !== nextProps.user.loggedIn) {
      this.changeLoginInNative(nextProps);
    }

    if (
      !_.isEmpty(nextProps.user.currentPurchaseEvent) &&
      !(
        _.startsWith(nextProps.location.pathname, "/purchase-event") ||
        _.startsWith(this.props.location.pathname, "/purchase-event")
      )
    ) {
      if (_.startsWith(nextProps.location.pathname, "/payment-method")) {
        if (
          !_.includes(getParams(nextProps.location).backPath, "purchase-event")
        ) {
          _.defer(() =>
            navigateTo(
              setParams("/purchase-event", {
                from: setParams(
                  nextProps.location.pathname,
                  getParams(nextProps.location),
                ),
              }),
            ),
          );
        }
      } else {
        _.defer(() =>
          navigateTo(
            setParams("/purchase-event", {
              from: setParams(
                nextProps.location.pathname,
                getParams(nextProps.location),
              ),
            }),
          ),
        );
      }
    }

    if (
      _.startsWith(this.props.location.pathname, "/payment-method") &&
      !_.isEmpty(this.props.user.currentPurchaseEvent) &&
      _.isEmpty(nextProps.user.currentPurchaseEvent)
    ) {
      navigateTo(getParams(this.props.location).backPath);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      pageContext: {businessAppConfiguration},
      location,
    } = this.props;

    //check if we need sync order items and if we are on needed page having menuItemsOrderData
    if (this.props.order.shouldSyncOrderItems && this.props.pageContext.menuData) {
       this.props.syncOrderItems(this.props.pageContext.menuData);
    }
    const usingNewsfeedLayout = isNewsfeedTemplateLayout(this.props.appStyles);

    if (prevProps.location.pathname !== this.props.location.pathname) {
      const isFullscreen =
        !(
          this.props.app.mobileAuthViewOpen ||
          this.props.app.authModalOpen ||
          usingNewsfeedLayout
        ) && this.props.location.pathname === "/";

      if (isFullscreen) {
        console.log("isFullscreen");
        document.body.parentElement.style.height = "100%";
      }
    }

    const { checkForPendingPurchaseEvents, pagePixels } =
      businessAppConfiguration || {};

    if (this.isNativeApp && checkForPendingPurchaseEvents) {
      if (
        !prevProps.user.shouldPollPendingPurchaseEvents &&
        this.props.user.shouldPollPendingPurchaseEvents
      ) {
        this.props.pollPendingPurchaseEvents();
      }

      if (
        !prevProps.user.shouldPollStatusPurchaseEvent &&
        this.props.user.shouldPollStatusPurchaseEvent &&
        !_.isEmpty(this.props.user.currentPurchaseEvent)
      ) {
        this.props.pollStatusPurchaseEvent(
          this.props.user.currentPurchaseEvent.id,
        );
      }
    }

    if (
      prevProps.user.amountOfGiftsUnseen !== this.props.user.amountOfGiftsUnseen
    ) {
      setIconBadgeNumberInNative(this.props.user.amountOfGiftsUnseen);
    }

    if (!prevProps.user.loggedIn && this.props.user.loggedIn) {
      this.props.getUnseenGiftsAmount();

      if (getParams(location).deepLinkGroupHash) {
        this.props.addDeepLinkParamsToUser(this.params);
        navigateTo(removeParams(location, "deepLinkGroupHash"));
      }
    }

    if (this.props.user.loggedIn) {
      const loadLoyaltyProfileData = _.get(
        this.props.user.loyaltyProfile,
        "data",
      );
      const loadLoyaltyProfileLoading = _.get(
        this.props.user.loyaltyProfile,
        "loading",
      );

      if (!loadLoyaltyProfileData && !loadLoyaltyProfileLoading) {
        this.props.loadLoyaltyProfile();
      }
    }

    if (
      !_.startsWith(prevProps.location.pathname, "/my-account") &&
      _.startsWith(location.pathname, "/my-account")
    ) {
      if (_.startsWith(getParams(location).onCloseTarget, "/order")) {
        navigateTo(setParams(location, { shouldNotAskToContinueOrder: true }));
      }
    }

    if (prevProps.location.pathname !== location.pathname) {
      const shouldRemoveContinueOrderParam = !(
        _.startsWith(location.pathname, "/my-account") ||
        _.startsWith(location.pathname, "/gifts") ||
        _.startsWith(location.pathname, "/order/items")
      );
      if (shouldRemoveContinueOrderParam) {
        navigateTo(removeParams(location, "shouldNotAskToContinueOrder"));
      }
    }
  }

  goToPrevPage = () => {
    const {
      app,
      user,
      order,
      location,
      pageContext,
      header,
      appStyles,
    } = this.props;

    if (location.pathname === "/") {
      window.postMessage(JSON.stringify({ type: "NO_PREVIOUS_PAGE" }));
    } else {
      if (this.isInStoreSignup()) {
        return;
      }
      const isPhoneWidth = app.isSSR || window.innerWidth < 768;
      const { backPath, backFunction, onClosePath } = getTitleAndBackPath(
        location,
        pageContext,
        {
          appStyles,
          user,
          order,
          mobileAuthViewOpen: app.mobileAuthViewOpen,
          checkoutOpenInMobile: isPhoneWidth && app.checkoutOpen,
          closeCheckoutView: () => {
            this.props.closeCheckoutView();
            navigateTo(removeParams(location, "checkoutOpen"));
          },
          openCheckoutView: () => {
            this.props.openCheckoutView();
            navigateTo(setParams("/order", getParams(location)));
          },
          groupReferralUrl: app.groupReferralUrl,
          isMobile: app.isMobile,
        },
      );

      if (backPath) {
        navigateTo(backPath);
      } else if (backFunction) {
        backFunction();
      } else if (onClosePath) {
        navigateTo(_.replace(onClosePath, "***", "?"));
      } else if (app.mobileAuthViewOpen) {
        this.props.closeAuthView();
      } else if (header && header.onCloseTarget) {
        navigateTo(_.replace(header.onCloseTarget, "***", "?"));
      }
    }
  };

  changePath = (pathname) => {
    const {
      app,
      pageContext: { servingOptions },
      location,
      user,
      openAuthView,
      closeAuthView,
    } = this.props;
    const params = getParams(location);

    if (
      _.startsWith(pathname, "/serving-options") ||
      _.startsWith(pathname, "/find-location")
    ) {
      closeAuthView();
    } else if (_.startsWith(pathname, "/gifts")) {
      user.loggedIn
        ? navigateTo("/gifts")
        : openAuthView(LOGIN_TYPES.GIFTS);
    } else if (_.startsWith(pathname, "/scan")) {
      user.loggedIn
        ? navigateTo("/scan")
        : openAuthView(LOGIN_TYPES.SCAN);
    } else {
      app.mobileAuthViewOpen && openAuthView();
      navigateTo(setParams(pathname, params));
    }
  };

  notifyLoginOnMount = () => {
    if (this.isInStoreSignup()) {
      return;
    }
    if (this.isNativeApp && window.isPostMessagePatched) {
      if (typeof window.postMessage === "function") {
        const action = {
          type: "NOTIFY_LOGIN_ON_MOUNT",
          payload: { userLoggedIn: this.props.user.loggedIn },
        };
        window.postMessage(JSON.stringify(action));
      }
    }
  };

  changeLoginInNative = (props) => {
    console.log("nativeApp:", this.isNativeApp);
    console.log("window.isPostMessagePatched:", window.isPostMessagePatched);
    if (this.isInStoreSignup()) {
      return;
    }
    if (this.isNativeApp && window.isPostMessagePatched) {
      if (typeof window.postMessage === "function") {
        console.log({ type: props.user.loggedIn ? "LOGGED_IN" : "LOGGED_OUT" });
        const action = {
          type: props.user.loggedIn ? "LOGGED_IN" : "LOGGED_OUT",
        };

        window.postMessage(JSON.stringify(action));
        this.props.markShowedPushApprovalPopup();
      }
    }
  };

  componentWillMount() {
    if (typeof window !== "undefined") {
      const {
        location,
        applyParamsToOrder,
        order,
        setGroupAppModeParams,
      } = this.props;

      const params = getParams(location);

      if (
        (params.servingOptionType &&
          params.servingOptionType !== order.servingOptionType) ||
        (params.branchId && params.branchId !== order.branchId) ||
        isGroupReferral(params)
      ) {
        applyParamsToOrder(params);

        if (isGroupReferral(params)) {
          setGroupAppModeParams(params);
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.splashScreenDelay);
    clearTimeout(this.splashScreenTimeout);
  }

  handleLoginClicked = () => {
    const {
      app,
      openAuthView,
      closeAuthView,
    } = this.props;

    if (app.authModalOpen || app.mobileAuthViewOpen) {
      closeAuthView();
      return;
    }
    openAuthView();
  };

  handleUserOpenAccountMenuClicked = () => {
    const { header } = this.props;
    const profileLinkTarget = setParams("/my-account", {
      onCloseTarget: header.onCloseTarget,
    });
    navigateTo(profileLinkTarget);
  };

  isCheckoutOpen = () => {
    const { app, order, location } = this.props;

    const isPhoneWidth = app.isSSR || window.innerWidth < 768;
    const checkoutOpen = isPhoneWidth
      ? app.checkoutOpen && !app.mobileAuthViewOpen
      : !app.userRequestedCheckoutClosedOnDesktop;
    return checkoutOpen;
  };

  isInStoreSignup = () => {
    const {
      pageContext: { kioskMode },
      location,
    } = this.props;
    return (
      !kioskMode &&
      (_.startsWith(location.pathname, "/config") ||
        _.startsWith(location.pathname, "/store"))
    );
  };

  onGoogleLoaded = (google) => {
    if (!this.googleLoaded) {
      this.googleLoaded = true;
      this.props.setGoogleLoaded(google);
    }
  };

  shouldShowUserProfileLink = () => {
    const {
      app,
      pageContext: { kioskMode },
      location,
      appStyles,
    } = this.props;

    return (
      !kioskMode &&
      !app.isSSR &&
      !_.startsWith(location.pathname, "/purchase-event") &&
      !_.startsWith(location.pathname, "/in-store-scan") &&
      !_.get(appStyles, "disabledFeatures", []).includes("signUp")
    );
  };

  openTemporarySplashScreen = () =>
    this.setState({ showTemporarySplashScreen: true });
  closeTemporarySplashScreen = () =>
    this.setState({ showTemporarySplashScreen: false });

  render() {
    if (!this.props.pageContext.business) {
      return null;
    }
    const {
      children,
      location,
      appStyles,
      pageContext: {
        businessAppConfiguration,
        branches,
        orderUrl,
        business: {
          links,
          scheme,
          mapsCredentialsKey,
        },
        business
      },
      header,
      T,
      user,
      app,
      order,
      giftRedeem,
      closeAuthView,
      closeCheckoutView,
      openCheckoutView,
      toggleCheckoutView,
      loadWaitingOrders,
      notifyIamHere,
      archiveWaitingOrder,
      openAuthView,
      closeAuthModal,
      openNewOrPrevOrderModal
    } = this.props;

    const temporarySplashScreenBackgroundColor = _.get(
      scheme,
      "temporarySplashScreenBackgroundColor",
    );
    const temporarySplashScreenEnabled = _.get(
      scheme,
      "showTemporarySplashScreen",
    );

    const isPhoneWidth = app.isSSR || window?.innerWidth < 768;
    const {
      headerTitle,
      backPath,
      showOnlyClose,
      closeFunction,
      showLogo,
      backFunction,
      onClosePath,
      noShadow,
    } = getTitleAndBackPath(location, this.props.pageContext, {
      appStyles,
      user,
      order,
      giftRedeem,
      mobileAuthViewOpen: app.mobileAuthViewOpen,
      checkoutOpenInMobile: isPhoneWidth && app.checkoutOpen,
      closeCheckoutView: () => {
        closeCheckoutView();
        navigateTo(removeParams(location, "checkoutOpen"));
      },
      openCheckoutView: () => {
        openCheckoutView();
        navigateTo(setParams("/order", getParams(location)));
      },
      kioskLocationId: app.kioskLocationId,
      groupReferralUrl: app.groupReferralUrl,
      isMobile: app.isMobile,
    });

    const withExtraHeader =
      giftRedeem.isDuringGiftRedeem || giftRedeem.isDuringDealRedeem;

    const checkoutOpen = this.isCheckoutOpen();
    const pageHasCheckoutPanel =
      this.state.layoutReady &&
      (app.isSSR ||
        (_.startsWith(location.pathname, "/order") &&
          !_.startsWith(location.pathname, "/order/items")));
    const isInStoreSignup = this.isInStoreSignup();

    const dontUseMapbox =
      businessAppConfiguration.useGoogleMapsForGeocoding ||
      appStyles.locale !== "en-US";

      const showBottomBar = isNewsfeedTemplateLayout(appStyles) || isTemplateEnabled(appStyles, HOMEPAGE_TEMPLATE_KEY.minimalist);    return (
      <div id="root" className="fullscreen">
        <Helmet
          app={app}
          T={T}
          location={location}
          appStyles={appStyles}
          business={business}
          branches={branches}
        />

        {_.map(appStyles.externalStyleSheets, (styleSheetHref) => (
          <link rel="stylesheet" href={styleSheetHref} />
        ))}
        <FontLoader appStyles={appStyles} />
        {businessAppConfiguration.useFullstory && <FullStory org={fs_org} />}

        <AppContainer
          app={app}
          appStyles={appStyles}
          relativePosition={app.keyboardOpen}
          mobileAuthViewOpen={app.mobileAuthViewOpen}
          withExtraHeader={withExtraHeader}
          checkoutOpen={checkoutOpen}
        >
          {temporarySplashScreenEnabled && (
            <TemporarySplashScreen
              T={T}
              appStyles={appStyles}
              open={this.state.showTemporarySplashScreen}
              onClose={this.closeTemporarySplashScreen}
              backgroundColor={temporarySplashScreenBackgroundColor}
            />
          )}
          {!this.props.isSSR &&
            businessAppConfiguration.useAfterOrderFeedback &&
            app.isMobile && (
              <WaitingOrders
                T={T}
                loadWaitingOrders={loadWaitingOrders}
                user={user}
                lastOrder={_.get(order, "lastOrderDetails")}
                onNotifyImHere={notifyIamHere}
                onArchive={archiveWaitingOrder}
                branches={branches}
                shrink={!(location.pathname === "/" || order.orderPlaced)}
              />
            )}
          <div id="mobileAuthView">
            <AuthView {...this.props} modalMode={!app.isMobile} />
          </div>
          {!this.props.isSSR && (
            <GoogleMapsLoader
              loadPlaces={dontUseMapbox}
              onLoaded={this.onGoogleLoaded}
              language={appStyles.defaultLanguage}
              apiKey={mapsCredentialsKey}
            />
          )}

          {!isInStoreSignup && (
            <Header
              T={T}
              backPath={backPath}
              backFunction={backFunction}
              title={T(headerTitle)}
              appStyles={appStyles}
              showLogo={showLogo}
              showOnlyClose={showOnlyClose}
              closeFunction={closeFunction}
              noShadow={noShadow}
              onCloseTarget={
                onClosePath
                  ? _.replace(onClosePath, "***", "?")
                  : _.replace(header.onCloseTarget, "***", "?")
              }
              relativePosition={app.keyboardOpen}
              mobileAuthViewOpen={app.mobileAuthViewOpen}
              closeMobileAuthView={() => {
                closeAuthModal(true);
                navigateTo(removeParams(location, ["type", "onLoginSuccess"]));
              }}
              hasCheckoutIcon={pageHasCheckoutPanel}
              panelOpen={checkoutOpen}
              extraHeader={
                withExtraHeader &&
                (({ style, className }) => (
                  <div id="extraHeader" style={style} className={className}>
                    <GiftsFlowHeader
                      giftRedeem={giftRedeem}
                      appStyles={appStyles}
                      T={T}
                    />
                  </div>
                ))
              }
            >
              {this.shouldShowUserProfileLink() && (
                <HeaderRightSide>
                  <TargetContext.Consumer>
                    {target => (
                      <>
                        {appStyles.customHeader && target === BUILD_TARGETS.ORDERING ? (
                          <HappysPizzaCustomRightSide
                            T={T}
                            appStyles={appStyles}
                            showLabel={!headerTitle}
                            loggedIn={user.loggedIn}
                            userDetails={user.userDetails}
                            loginLabelText={T("Login")}
                            loginLabelDesktopExtraText={T(" / Signup")}
                            onLoginClicked={this.handleLoginClicked}
                            onUserOpenAccountMenuClicked={
                              this.handleUserOpenAccountMenuClicked
                            }
                            amountOfGiftsUnseen={user.amountOfGiftsUnseen}
                            checkoutOpen={pageHasCheckoutPanel && checkoutOpen}
                          />
                        ) : (
                          <HeaderUserProfileLink
                            T={T}
                            appStyles={appStyles}
                            showLabel={!headerTitle}
                            loggedIn={user.loggedIn}
                            userDetails={user.userDetails}
                            loginLabelText={T("Login")}
                            loginLabelDesktopExtraText={T(" / Signup")}
                            onLoginClicked={this.handleLoginClicked}
                            onUserOpenAccountMenuClicked={
                              this.handleUserOpenAccountMenuClicked
                            }
                            amountOfGiftsUnseen={user.amountOfGiftsUnseen}
                            orderUrl={orderUrl}
                          />
                        )}
                      </>
                    )}
                  </TargetContext.Consumer>
                  {pageHasCheckoutPanel && (
                    <HeaderCheckoutBagToggle
                      T={T}
                      location={location}
                      order={order}
                      app={app}
                      toggleCheckoutView={toggleCheckoutView}
                      appStyles={appStyles}
                      checkoutOpen={checkoutOpen}
                    />
                  )}
                </HeaderRightSide>
              )}
            </Header>
          )}
          {pageHasCheckoutPanel && (
            <div
              id="checkout"
              className={classnames(
                styles.CheckoutSideBar,
                checkoutOpen && styles.Open,
                appStyles.rtl && styles.RTL,
              )}
            />
          )}

          {children}

          <TargetContext.Consumer>
            {(target) => (
              target === BUILD_TARGETS.ORDERING && !app.isSSR &&
              isPhoneWidth &&
              showBottomBar && (
                <AppTabs
                  T={T}
                  appStyles={appStyles}
                  context={{order, user, app}}
                  onOpenMobileAuthView={openAuthView}
                  onCloseMobileAuthView={closeAuthView}
                  openNewOrPrevOrderModal={openNewOrPrevOrderModal}
                />
              )
            )}
          </TargetContext.Consumer>
        </AppContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { user, header, order, nativeTabs, app, giftRedeem } = state;

  return {
    appStyles: getStyles(state, props),
    promotions: getPromotions(state, props),
    header,
    user,
    order,
    app,
    giftRedeem,
    nativeTabs,
    loggedIn: !!(user && user.userId),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleCheckoutView: () => dispatch(toggleCheckoutView()),
    closeCheckoutView: () => dispatch(closeCheckoutView()),
    openCheckoutView: () => dispatch(openCheckoutView()),
    openAuthView: (loginType) => dispatch(openAuthView(loginType)),
    closeAuthView: (params) => dispatch(closeAuthView(params)),
    closeAuthModal: (params) => dispatch(closeAuthModal(params)),
    initUniqueIdentifierIfNeeded: () =>
      dispatch(initUniqueIdentifierIfNeeded()),
    applyParamsToOrder: (params) => dispatch(applyParamsToOrder(params)),
    resetGiftRedeem: () => dispatch(resetGiftRedeem()),
    loadContactsSuccess: (params) => dispatch(loadContactsSuccess(params)),
    loadContactsFailed: (params) => dispatch(loadContactsFailed(params)),
    setKeyboardOpen: (keyboardOpen) => dispatch(setKeyboardOpen(keyboardOpen)),
    registerPushNotifications: (registrationInfo) =>
      dispatch(registerPushNotifications(registrationInfo)),
    resetAuth: () => dispatch(resetAuth()),
    setCameraPermissionsAndroid: ({ granted, error }) =>
      dispatch(setCameraPermissionsAndroid({ granted, error })),
    pollPendingPurchaseEvents: () => dispatch(pollPendingPurchaseEvents()),
    pollStatusPurchaseEvent: (purchaseEventId) =>
      dispatch(pollStatusPurchaseEvent(purchaseEventId)),
    startNewOrder: () => dispatch(startNewOrder()),
    setKioskLocationId: (kioskLocationId) =>
      dispatch(setKioskLocationId(kioskLocationId)),
    getUnseenGiftsAmount: () => dispatch(getUnseenGiftsAmount()),
    setGoogleLoaded: (google) => dispatch(setGoogleLoaded(google)),
    changeLocation: (location) => dispatch(changeLocation(location)),
    loadUserDetails: (forcedUserParams) =>
      dispatch(loadUserDetails(forcedUserParams)),
    addDeepLinkParamsToUser: (params) => dispatch(addDeepLinkParamsToUser(params)),
    setGroupAppModeParams: (params) => dispatch(setGroupAppModeParams(params)),
    setEditorAppStyles: (params) => dispatch(setEditorAppStyles(params)),
    setEditorPromotions: (params) => dispatch(setEditorPromotions(params)),
    loadWaitingOrders: (params) => dispatch(loadWaitingOrders(params)),
    notifyIamHere: (...params) => dispatch(notifyIamHere(...params)),
    archiveWaitingOrder: (params) => dispatch(archiveWaitingOrder(params)),
    loadLoyaltyProfile: () => dispatch(loadLoyaltyProfile()),
    closePrivacyConsent: () => dispatch(closePrivacyConsent()),
    showPrivacyConsent: () => dispatch(showPrivacyConsent()),
    markShowedPushApprovalPopup: () => dispatch(markShowedPushApprovalPopup()),
    openNewOrPrevOrderModal: () => dispatch(openNewOrPrevOrderModal()),
    syncOrderItems: (menuData) => dispatch(syncOrderItems(menuData))
  };
};

const fs_org = "82MM4";

const NATIVE_INIT = "NATIVE_INIT";
const CHANGE_PATH = "CHANGE_PATH";
const GO_TO_PREV_PAGE = "GO_TO_PREV_PAGE";

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(TemplateWrapper));
