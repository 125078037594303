import React, { useEffect } from "react";
import { omit } from "lodash";
import { Location } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import MobileDetect from "mobile-detect";
import Layout from "./src/layouts/index";
import SecurityProvider from "./src/components/security-provider";
import { setIsMobileView } from "./src/store/app/actions";
import { ProvideTargetContext } from "./src/target-context";

export default ({ element, props }) => {
  const { pageContext: { target = 'ordering' } } = props;
  const isMobileFromStore = useSelector(({ app: { isMobile } }) => isMobile);
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const {
    location: { pathname },
    pageContext,
  } = props;

  if (
    props.custom404 ||
    element.key === "/404.html" ||
    pathname === "/404.html" ||
    pathname === "/404/"
  ) {
    return element;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile =
      Boolean(md && md.mobile()) ||
      window.matchMedia("(max-width: 767px)").matches

    if (isMobile !== isMobileFromStore) {
      dispatch(setIsMobileView(isMobile));
    }
  }, [isMobileFromStore]);

  const businessAppConfiguration = pageContext.businessAppConfiguration || {};
  const { useRecaptchaInSignUp } = businessAppConfiguration;
  const { recaptchaKey } = businessAppConfiguration;
  // const layoutData = merge(
  //   ...map(values(get(props, "pageResources.staticQueryResults")), "data"),
  // );
  // console.log(element);
  return (
    <ProvideTargetContext target={target}>

      <Location>
        {({ location }) => (
          <SecurityProvider
            useReacptcha={useRecaptchaInSignUp}
            recaptchaKey={recaptchaKey}
          >
            <Layout {...omit(props, "data")} location={location}>
              {element}
            </Layout>
          </SecurityProvider>
        )}
      </Location>
    </ProvideTargetContext>
  );
};
